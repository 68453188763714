// Arc Infrastructure

export default {
  settings: {
    disableIframePostMessage: false,
  },
  content: {
    general: {
      clientName: "Arc Infrastructure",
    },
    superFund: `
      <div class="section-title mt-5">
        <h2>
          Arc Infrastructure Superannuation Funds
        </h2>
      </div>
      <div class="row content">
        <div class="col-lg-6">
          <p>
            You have a choice of two funds as an employee of Arc Infrastructure. <a href='http://www3.colonialfirststate.com.au/' target='_blank'>Colonial First State</a> has tailored pricing and insurances for Arc Infrastructure employees or you can choose <a href='https://www.australiansuper.com/compare-us/our-performance' target='_blank'>Australian Super</a>, Australia’s largest Industry Fund.
          </p>
          <p>
            This website provides all the information you need around the Arc Infrastructure Superannuation Fund. There are helpful super tools and calculators, and information on other services we offer if you require additional support with your financial well-being.
          </p>
          <div class="d-flex justify-content-around btn-wrapper">
            <div class="col-lg-5 pt-4 pb-1 px-0 text-center text-lg-center">
              <a
                target="_blank"
                href="https://www.colonialfirststate.com.au/firstnet/login.aspx?CompanyCode=001"
                class="btn-learn-more"
              >
                Log in to Colonial First State website
              </a>
            </div>
            <div class="col-lg-5 pt-4 pb-1 px-0 text-center text-lg-center">
              <a
                target="_blank"
                href="https://portal.australiansuper.com/login.aspx"
                class="btn-learn-more"
              >
                Log in to Australian Super website
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-6 income-protection">
          <h3>Company Funded Income Protection</h3>
          <p>Arc Infrastructure provide their employees (not casuals) with income protection, based on the following design, with MLC. Employees receive this irrespective of the Super Fund they choose.</p>
          <ul>
            <li>
              <i class="ri-check-double-line"></i>
              <p>Insures 75% of base salary, plus super contribution</p>
            </li>
            <li>
              <i class="ri-check-double-line"></i>
              <p>90 day waiting period</p>
            </li>
            <li>
              <i class="ri-check-double-line"></i>
              <p>2 year benefit period</p>
            </li>
            <li>
              <i class="ri-check-double-line"></i>
              <p><strong>Vivo Virtual Care</strong>: advice and guidance from leading health experts, anywhere, anytime.
                <a target="_blank" href="https://vivovirtualcare.com.au/">
                  Learn more about the Vivo Virtual Care
                </a>
              </p>
            </li>
          </ul>
        </div>
      </div>
    `,
    pdfs: [
      {
        title: "Building your Super Fact Sheet",
        description:
          "View all the different ways you can build your superannuation savings.",
        type: "form",
        url: "Gallagher-Building_Your_Super_Factsheet-2024-2025.pdf",
      },
      {
        title: "Join the Arc Infrastructure fund - Australian Super",
        description:
          "A summary of benefits available to you, including your super choice form.",
        type: "form",
        url: "ARC_Infra-New_Employee_Pack-October_2023_Aus_Super.pdf",
      },
      {
        title: "Join the Arc Infrastructure fund - Colonial First State",
        description:
          "A summary of benefits available to you, including your super choice form.",
        type: "form",
        url: "ARC_Infra-New_Employee_Pack-October_2023_CFS.pdf",
      },
      {
        title: "Colonial First State forms",
        description: "Do you need to change any of your details?",
        type: "form",
        externalUrl:
          "https://www.cfs.com.au/personal/resources/find-a-form/find-a-form.html#keyword=&productType=superannuation",
      },
      {
        title: "ATO Rollover Form",
        description:
          "Do you need to rollover your previous super fund to your new super fund?",
        type: "form",
        externalUrl:
          "https://www.ato.gov.au/Forms/Request-for-rollover-of-whole-balance-of-super-benefits-between-funds---Instructions/#Howtogettheform",
      },
      {
        title: "Colonial First State - First Benefits",
        description: "Extra benefits from your super fund.",
        type: "info",
        url: "Colonial-First-State-Company-Benefits-1.pdf",
      },
      {
        title: "Colonial First State - Product Disclosure Statement",
        description: "In-depth information on your super fund.",
        type: "info",
        externalUrl:
          "https://www.colonialfirststate.com.au/forms_tools/download_multi_pdf.aspx?name=FirstChoice+Employer+Super+PDS&pdf=FS743.pdf,FS734.pdf,FS1999.pdf,FS7913.pdf,FS8063.pdf,FS5115.pdf,FS5113.pdf,FS5383.pdf,FS8110.pdf&LiteratureId386",
      },
      {
        title: "Australian Super Forms",
        description: "Visit the Australian Super website to view their forms.",
        type: "form",
        externalUrl:
          "https://www.australiansuper.com/tools-and-advice/forms-and-fact-sheets",
      },
      {
        title: "MLC - Company Funded Income Protection",
        description:
          "Arc Infrastructure provide an important insurance benefit to their Employees.",
        type: "info",
        url: "ARC_Infrastructure_GSC_Flyer-2024-Staff.pdf",
      },
      {
        title: "Australian Super Superannuation Insights Report",
        description: "Discover the latest information.",
        type: "info",
        url: "Arc_AustralianSuper Report_August_2023.pdf",
      },
    ],
    teamMembers: [
      {
        teamMemberPic: "anthony.png",
        name: "Anthony Warman",
        phoneHref: "0862508308",
        phone: "08 6250 8308",
        email: "anthony_warman@ajg.com.au",
        social: "http://au.linkedin.com/pub/anthony-warman/7b/53a/75a",
      },
      {
        teamMemberPic: "phil.png",
        name: "Phil Mills",
        phoneHref: "0862508399",
        phone: "08 6250 8399",
        email: "phil_mills@ajg.com.au",
        social: "https://au.linkedin.com/pub/philip-mills/67/701/268",
      },
      {
        teamMemberPic: "natasha.png",
        name: "Natasha Van",
        phoneHref: "1300557782",
        phone: "1300 557 782",
        email: "super-service@ajg.com.au",
      },
    ],
    footerClientName: "Arc Infrastructure",
  },
  superscore: false,

  analytics: {
    gacode: "",
    gaprod: "",
  },
};
